<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title mb-0">
              {{cvCardTitle}}: <span> {{carname}}</span>
            </h4>
          </template>
          <template v-slot:body>
            <ValidationObserver ref="carrForm">
              <form action="#" v-if="vmCareerFormData && Object.keys(vmCareerFormData).length > 0">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_field" class="w-100">
                      {{cvCarrImageLabel}}</label>
                    <div class="profile-img-edit border border-dark" style="height: 150px; width: 150px; border-radius: 50%; ">
                      <div v-if="vmProfilePic" >
                      <img alt="profile-pic " :src="vmProfilePic" style="height: 100%; width: 100%; object-fit: contain; overflow: hidden; border-radius: 50%; "/>
                      <button class="btn btn-primary ml-5 mt-1" @click="()=>imageEdit('Profile Pic')">
                        Save
                      </button>
                    </div>
                      <i v-else class="fas fa-orgersity d-flex justify-content-center align-items-center rounded-circle " style="font-size: 5rem; height:100%; "></i>
                      <label class="p-image">
                        <i class="ri-pencil-line upload-button" title="Update image" style="cursor: pointer" ></i>
                        <input class="file-upload" type="file" accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0;" @change="openImageDialog($event, 'Profile Pic')" />
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_field">
                      {{cvCarrFieldLabel}}</label>
                      <ValidationProvider
                      name="Career Field"
                      rules="required"
                      v-slot="{ errors }">
                        <input
                        v-model="vmCareerFormData.carr_field"
                        type="text"
                        class="form-control"
                        required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_desc">
                      {{cvCarrDescLabel}}</label>
                      <ValidationProvider
                      name="Career Description"
                      rules="required"
                      v-slot="{ errors }">
                    <vue-editor v-model="vmCareerFormData.carr_desc"></vue-editor>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                 </div>
                 <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_hiring_org">
                      {{cvCarrHiringOrgLabel}}</label>
                      <ValidationProvider
                      name="Career Hiring organization"
                      rules="required"
                      v-slot="{ errors }">
                    <vue-editor v-model="vmCareerFormData.carr_hiring_org"></vue-editor>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_facts_stories">
                      {{cvCarrFactsStoriesLabel}}</label>
                      <ValidationProvider
                      name="Career Facts Stories"
                      rules="required"
                      v-slot="{ errors }">
                      <vue-editor v-model="vmCareerFormData.carr_facts_stories"></vue-editor>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_field">
                      {{cvCarrCategoryLabel}}</label>
                    <select v-model="vmCareerFormData.carr_category" class="form-control">
                      <option disabled value="">Please select one</option>
                      <option v-for="item in cvcareerCategoryOptions" :key="item.carr_cat_name">{{ item.carr_cat_name }}</option>
                    </select>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_role_responsibility">
                      {{cvCarrRoleResponsibilityLabel}}</label>
                      <ValidationProvider
                      name="Career Role Responsibility"
                      rules="required"
                      v-slot="{ errors }">
                    <vue-editor v-model="vmCareerFormData.carr_role_responsibility"></vue-editor>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_journey">
                      {{cvCarrJourneyLabel}}</label>
                      <ValidationProvider
                      name="Career Journey"
                      rules="required"
                      v-slot="{ errors }">
                    <vue-editor v-model="vmCareerFormData.carr_journey"></vue-editor>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_myth_buster">
                      {{cvCarrMythBusterLabel}}</label>
                      <ValidationProvider
                      name="Career Myth Buster"
                      rules="required"
                      v-slot="{ errors }">
                    <vue-editor v-model="vmCareerFormData.carr_myth_buster"></vue-editor>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="mt-4 col-12">
                      <div class="form-group row align-items-center">
                        <div class="col-md-12">
                          <label for="validationcarr_myth_buster">
                          {{ cvCarrCoverImageLabel }}</label>
                          <div class="profile-img-edit border" style="height: 250px; width: 100%;">
                            <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                            <i v-else class="fas fa-university d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                            <label class="p-image">
                              <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                              <input class="file-upload" type="file" accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                            </label>
                          </div>
                        </div>
                        <span class="text-danger" ref="coverPicError"></span>
                      </div>
                      <button type="button" class="p-2 mr-3 mb-3 rounded btn btn-primary border-0 " @click="imageEdit('Cover Pic')" >
                      <b-spinner small class="ml-4 mr-4" v-if="cvCoverPicLoading" label="Spinning">
                      </b-spinner>
                      <span v-else> Save Cover Pic</span>
                    </button>
                    <button v-if="vmCoverPic" type="button" class="p-2 mb-3 rounded btn btn-primary border-0 " :disabled="cvProfilePicError || cvProfilePicLoading"  @click="imageRemove('cover_pic')">
                      <b-spinner small class="ml-4 mr-4" v-if="cvDelProfilePicLoading" label="Spinning">
                      </b-spinner>
                      <span v-else>Remove Cover Pic</span>
                    </button>
                    </div>
                </div>
                <div class="form-group row spinner_add ">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning">
                    </b-spinner>
                  </button>
                  <button type="button" class="btn btn-primary ml-2" @click="careerEdit()">
                    {{cvSubmitBtn}}
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { Careers } from "../../../../FackApi/api/career"
import ApiResponse from "../../../../Utils/apiResponse.js"
import { VueEditor } from "vue2-editor"
import CropImage from "../../../../components/cropImage.vue"
import { Images } from "../../../../FackApi/api/image"
import { ValidationProvider } from "vee-validate"
import { CareerCategorys } from "../../../../FackApi/api/careerCategory.js"
import { socialvue } from "../../../../config/pluginInit"
import userPermission from "../../../../Utils/user_permission.js"

export default {
  name: "CareerEdit",
  components: {
    VueEditor,
    ValidationProvider,
    CropImage
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propCareerObj: {
      type: Object,
      default: function () {
        return {
          "carr_field": "",
          "carr_desc": "",
          "carr_hiring_org": "",
          "carr_facts_stories": "",
          "carr_category": "",
          "carr_role_responsibility": "",
          "carr_journey": "",
          "carr_myth_buster": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "career_edit",
      cvCardTitle: "Edit Career",
      cvCardSubHeader: "Edit Career",
      cvSubmitBtn: "Edit",
      cvCarrFieldLabel: "carrer Name",
      cvCarrImageLabel: "carrer Image",
      cvCarrDescLabel: "carrer description",
      cvCarrHiringOrgLabel: "carrer hiring org",
      cvCarrFactsStoriesLabel: "carrer facts stories",
      cvCarrCategoryLabel: "carrer category",
      cvCarrRoleResponsibilityLabel: "carrer role responsibility",
      cvCarrJourneyLabel: "carrer journey",
      cvCarrMythBusterLabel: "carrer myth buster",
      cvCarrCoverImageLabel: "career cover Image",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Career",
      vmCareerProPicImage: Object.assign({}, this.initImageData()),
      propCropImageDialogOpen: false,
      propCropDialogSize: "md",
      propStencilSize: null,
      fileSrc: "",
      carname: "",
      vmProfilePic: "",
      cvcareerCategoryOptions: "",
      vmCareerFormData: {},
      cvCoverPicLoading: ""
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    this.carId = this.$route.params.carr_id

    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.careerView()
    this.careerCategoryList()
    this.imageGetProfileImages()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCareerFormData) {
          if (!this.vmCareerFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        module_id: this.carId,
        module_name: "CARR",
        image: [],
        image_type: {}
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
    * onChangeProfileImage
    */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      if (imageType === "Profile Pic") {
        this.vmCareerProPicImage = Object.assign({}, this.initImageData())
        this.vmCareerProPicImage.image.push(
          {
            "img": img,
            "name": orgImg.name
          }
        )
        this.vmCareerProPicImage.image_type[orgImg.name] = 2
      }
      else {
        this.vmOrganisationCoverPicImage = Object.assign({}, this.initImageData())
        this.vmOrganisationCoverPicImage.image.push(
          {
            "img": img,
            "name": orgImg.name
          }
        )
        this.vmOrganisationCoverPicImage.image_type[orgImg.name] = 3
      }
    },
    /**
     * imageRemove
     */
    async imageRemove (imgType) {
      if (imgType === "profile_pic") {
        this.cvDelProfilePicLoading = true
      }
      else {
        this.cvDelCoverPicLoading = true
      }
      let img = imgType === "profile_pic" ? 2 : 3
      const imageUpdateResp = await Images.imageDelete(this, this.$route.params.org_id, img)
      await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
      if (imageUpdateResp && !imageUpdateResp.resp_status) {
        return false
      }
      if (imgType === "profile_pic") {
        this.vmProfilePic = ""
        this.cvDelProfilePicLoading = false
      }
      else {
        this.vmCoverPic = ""
        this.cvDelCoverPicLoading = false
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024
        this.toastVariant = "danger"
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          return false
        }
        this.toastVariant = ""
        if (imageType === "Profile Pic") {
          this.propStencilSize = {
            "width": 150,
            "height": 150
          }
          this.propCropDialogSize = "md"
        }
        else {
          this.propStencilSize = {
            "width": 970,
            "height": 240
          }
          this.propCropDialogSize = "xl"
        }
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * imageGetProfileImages()
     */
    async imageGetProfileImages () {
      try {
        let carId = this.$route.params.carr_id
        let imageProfileResp = await Images.imageListProfileImages(this, carId)
        if (imageProfileResp && !imageProfileResp.resp_status) {
          return false
        }
        else {
          this.profilePicObj = imageProfileResp.resp_data.profile_pic
          this.coverPicObj = imageProfileResp.resp_data.cover_pic
          this.vmProfilePic = imageProfileResp.resp_data.profile_pic ? imageProfileResp.resp_data.profile_pic.image_thumbnail : ""
          this.vmCoverPic = imageProfileResp.resp_data.cover_pic ? imageProfileResp.resp_data.cover_pic.image : ""
        }
      }
      catch (err) {
        console.error("Exception occurred at imageGetProfileImages() and Exception:", err.message)
      }
    },
    /**
     * imageEdit
     */
    async imageEdit (imageType) {
      if (imageType === "Profile Pic" && this.vmCareerProPicImage.image.length === 0) {
        return false
      }
      if (imageType === "Cover Pic" && this.vmOrganisationCoverPicImage.image.length === 0) {
        return false
      }
      try {
        let image
        if (imageType === "Profile Pic") {
          this.cvProfilePicLoading = true
          image = this.vmCareerProPicImage
        }

        else {
          this.cvCoverPicLoading = true
          image = this.vmOrganisationCoverPicImage
        }
        const imageUpdateResp = await Images.imageProfileUpdate(this, image)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        let imageResp = imageUpdateResp.resp_data
        if (imageResp && imageResp.length > 0) {
          if (imageType === "Profile Pic") {
            this.profilePicObj = imageResp[0]
          }
          else {
            this.coverPicObj = imageResp[0]
          }
          await this.addOrgToModules()
        }
        this.vmCareerProPicImage = Object.assign({}, this.initImageData())
      }
      catch (err) {
        console.error("Exception occurred at imagEdit() and Exception:", err.message)
      }
      finally {
        if (imageType === "Profile Pic") {
          this.cvProfilePicLoading = false
        }
        else {
          this.cvCoverPicLoading = false
        }
      }
    },
    /**
     * careerView
     */
    async careerView () {
      try {
        if (this.propOpenedInModal) {
          this.vmCareerFormData = this.propCareerObj
        }
        else {
          let careerId = this.$route.params.carr_id
          let careerViewResp = await Careers.careerView(this, careerId)
          this.carname = careerViewResp.resp_data.data.carr_field
          if (careerViewResp && careerViewResp.resp_status) {
            this.vmCareerFormData = careerViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at careerView() and Exception:", err.message)
      }
    },
    /**
     * careerCategoryList
     */
    async careerCategoryList () {
      try {
        let careerCategoryListResp = await CareerCategorys.careerCategoryList(this)
        this.cvcareerCategoryOptions = careerCategoryListResp.resp_data.data

        if (careerCategoryListResp.resp_status) {
          this.careerCategoryObjList = careerCategoryListResp.resp_data.data
          this.totalRows = careerCategoryListResp.resp_data.count
        }
        else {
          this.toastMsg = careerCategoryListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at careerCategoryList() and Exception:", err.message)
      }
    },
    /**
     * careerEdit
     */
    async careerEdit () {
      let valid = await this.$refs.carrForm.validate()
      if (!valid) {
        return
      }
      try {
        this.cvLoadingStatus = true
        let careerAddResp = await Careers.careerEdit(this, this.vmCareerFormData)
        await ApiResponse.responseMessageDisplay(this, careerAddResp)
        if (careerAddResp && !careerAddResp.resp_status) {
          return false
        }
        if (careerAddResp && !careerAddResp.resp_status) {
          this.cvLoadingStatus = false
        //  const resp = await imageEdit("Profile Pic")
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCareerEditModal", this.vmCareerFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at careerEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
