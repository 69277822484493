<template>
  <b-container fluid >
    <b-row>
      <b-col class="heading_alignment" md="12">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="careerAdd">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template>
            <b-row >
              <b-col class="col-12 col-sm-8 col-md-6 col-lg-7 mb-3">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col class="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-if="careerObjList && careerObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="careerObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(carr_field)="data">
                    <p v-html="data.item.carr_field">
                    </p>
                    <b-button class="btn actionIcon mr-1 mb-1" size="sm" @click="careerEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="ri-ball-pen-fill m-0 font-size-20" title="Edit"></i>
                    </b-button>
                    <b-button class="btn actionIcon" @click="showCareerDeleteDialog(data.item)" size="sm">
                      <i class="ri-delete-bin-line m-0 font-size-20" title="Delete"></i>
                    </b-button>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(carr_desc)="data">
                    <p v-html="data.item.carr_desc" class="threeLineOnly vhtmlTag vueHtmlParent">
                    </p>
                  </template>
                  <template v-slot:cell(carr_hiring_org)="data">
                    <p v-html="data.item.carr_hiring_org" class="threeLineOnly vhtmlTag vueHtmlParent">
                    </p>
                  </template>
                  <template v-slot:cell(carr_facts_stories)="data">
                    <p v-html="data.item.carr_facts_stories" class="threeLineOnly vhtmlTag vueHtmlParent">
                    </p>
                  </template>
                  <template v-slot:cell(carr_journey)="data">
                    <p v-html="data.item.carr_journey" class="threeLineOnly vhtmlTag vueHtmlParent">
                    </p>
                  </template>
                  <template v-slot:cell(carr_role_responsibility)="data">
                    <p v-html="data.item.carr_role_responsibility" class="threeLineOnly vhtmlTag vueHtmlParent" >
                    </p>
                  </template>
                  <template v-slot:cell(carr_myth_buster)="data">
                    <p v-html="data.item.carr_myth_buster" class="threeLineOnly vhtmlTag vueHtmlParent">
                    </p>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelCareerAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl">
      <CareerAdd :propOpenedInModal="true" @emitCloseCareerAddModal="closeCareerAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="careerAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCareerAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelCareerEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <CareerEdit :propOpenedInModal="true" :propCareerObj="careerEditObj" @emitCloseCareerEditModal="closeCareerEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="careerEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCareerEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelCareerDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelCareerDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="careerDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { Careers } from "../../../FackApi/api/career.js"
import CareerAdd from "../../Gide/ListPages/Career/CareerAdd.vue"
import CareerEdit from "../../Gide/ListPages/Career/CareerEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"

export default {
  name: "CareerList",
  components: {
    CareerAdd,
    CareerEdit,
    CsvUpload,
    CsvDownload
  },
  data () {
    return {
      cvCardTitle: "Careers",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Career",
      cvAddModalHeader: "Add Career",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Career List Response",
      showModelCareerAdd: false,
      showModelCareerEdit: false,
      showModelCareerDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "career", key: "carr_field", class: "text-left align-text-top", sortable: true },
        { label: "carr desc", key: "carr_desc", class: "text-left  align-text-top limitedCharDesc", sortable: true },
        { label: "carr hiring org", key: "carr_hiring_org", class: "text-left align-text-top limitedCharDesc", sortable: true },
        { label: "carr facts stories", key: "carr_facts_stories", class: "text-left align-text-top limitedCharDesc", sortable: true },
        { label: "carr category", key: "carr_category", class: "text-left align-text-top", sortable: true },
        { label: "carr role responsibility", key: "carr_role_responsibility", class: "text-left align-text-top", sortable: true },
        { label: "carr journey", key: "carr_journey", class: "text-left align-text-top", sortable: true },
        { label: "carr myth buster", key: "carr_myth_buster", class: "text-left align-text-top limitedCharDesc", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true }
      ],
      careerObjList: null,
      careerEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null,
      cvModulePrefix: "CARR",
      cvModuleFile: "career"
    }
  },
  mounted () {
    this.careerList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * careerList
     */
    async careerList () {
      try {
        let careerListResp = await Careers.careerList(this)
        if (careerListResp.resp_status) {
          this.careerObjList = careerListResp.resp_data.data
          this.totalRows = careerListResp.resp_data.count
        }
        else {
          this.toastMsg = careerListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at careerList() and Exception:", err.message)
      }
    },
    /**
     * careerAdd
     */
    careerAdd (type) {
      try {
        this.$router.push("/career_add")
      }
      catch (err) {
        console.error("Exception occurred at careerAdd() and Exception:", err.message)
      }
    },
    /**
     * careerEdit
     */
    careerEdit (type, item) {
      try {
        this.careerEditObj = item
        this.$router.push("/career_edit/" + this.careerEditObj.carr_id)
      }

      catch (err) {
        console.error("Exception occurred at careerEdit() and Exception:", err.message)
      }
    },
    /**
     * showCareerDeleteDialog
     */
    showCareerDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelCareerDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showCareerDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * careerDelete
     */
    async careerDelete () {
      try {
        let careerDelResp = await Careers.careerDelete(this, this.delObj.carr_id)
        await ApiResponse.responseMessageDisplay(this, careerDelResp)
        if (careerDelResp && !careerDelResp) {
          this.showModelCareerDelete = false
          return false
        }
        let index = this.careerObjList.indexOf(this.delObj)
        this.careerObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelCareerDelete = false
      }
      catch (err) {
        console.error("Exception occurred at careerDelete() and Exception:", err.message)
      }
    },
    /**
     * closeCareerAddModal
     */
    closeCareerAddModal (careerAddData) {
      try {
        if (careerAddData) {
          if (this.careerObjList && this.careerObjList.length >= 1) {
            let carrObjLength = this.careerObjList.length
            let lastId = this.careerObjList[carrObjLength - 1]["id"]
            careerAddData.id = lastId + 1
          }
          else {
            this.careerObjList = []
            careerAddData.id = 11111
          }

          careerAddData.carr_id = `CARR${careerAddData.id}`
          careerAddData.created_on = moment()
          this.careerObjList.unshift(careerAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelCareerAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closecareerAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeCareerEditModal
     */
    closeCareerEditModal () {
      try {
        this.showModelCareerEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeCareerEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
